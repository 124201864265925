import { useTranslation } from 'react-i18next';

import { Tag } from '@/components/Tag/Tag';
import { Typography } from '@/components/Typography/Typography';
import { QuestionnaireStatus } from '@/core/enums/functions/performanceProfile/questionnaires/questionnaireStatusEnum';
import { Parameter } from '@/core/enums/parametersEnum';
import { useAppParameterColorValue } from '@/core/hooks/useAppParameter';

export const useQuestionnaireTags = () => {
  const { t: questionnaireTranslations } = useTranslation('questionnaire', { keyPrefix: 'status' });

  // tag color parametrs
  const startedStatusTagBgColor = useAppParameterColorValue(Parameter.StartedStatusTagBgColor);
  const startedStatusTagTextColor = useAppParameterColorValue(Parameter.StartedStatusTagTextColor);
  const completeStatusTagBgColor = useAppParameterColorValue(Parameter.CompleteStatusTagBgColor);
  const completeStatusTagTextColor = useAppParameterColorValue(
    Parameter.CompleteStatusTagTextColor
  );
  const readyForClosingStatusTagBgColor = useAppParameterColorValue(
    Parameter.ReadyForClosingStatusTagBgColor
  );
  const readyForClosingStatusTagTextColor = useAppParameterColorValue(
    Parameter.ReadyForClosingStatusTagTextColor
  );
  const closedStatusTagBgColor = useAppParameterColorValue(Parameter.ClosedStatusTagBgColor);
  const closedStatusTagTextColor = useAppParameterColorValue(Parameter.ClosedStatusTagTextColor);

  const statusBackground: Record<QuestionnaireStatus, React.CSSProperties> = {
    [QuestionnaireStatus.Started]: {
      backgroundColor: startedStatusTagBgColor,
      color: startedStatusTagTextColor,
    },
    [QuestionnaireStatus.Complete]: {
      backgroundColor: completeStatusTagBgColor,
      color: completeStatusTagTextColor,
    },
    [QuestionnaireStatus.ReadyForClosing]: {
      backgroundColor: readyForClosingStatusTagBgColor,
      color: readyForClosingStatusTagTextColor,
    },
    [QuestionnaireStatus.Closed]: {
      backgroundColor: closedStatusTagBgColor,
      color: closedStatusTagTextColor,
    },
  };

  const questionnaireStatuses: Record<QuestionnaireStatus, React.ReactNode> = {
    [QuestionnaireStatus.Started]: (
      <Tag
        style={statusBackground[QuestionnaireStatus.Started]}
        label={
          <>
            <Typography className='text-inherit' type={'14-M'}>
              {questionnaireTranslations('questionnaireStatus.started.label')}
            </Typography>
          </>
        }
      />
    ),
    [QuestionnaireStatus.Complete]: (
      <Tag
        style={statusBackground[QuestionnaireStatus.Started]}
        label={
          <>
            <Typography className='text-inherit' type={'14-M'}>
              {questionnaireTranslations('questionnaireStatus.complete.label')}
            </Typography>
          </>
        }
      />
    ),
    [QuestionnaireStatus.ReadyForClosing]: (
      <Tag
        style={statusBackground[QuestionnaireStatus.ReadyForClosing]}
        label={
          <>
            <Typography className='text-inherit' type={'14-M'}>
              {questionnaireTranslations('questionnaireStatus.readyForClosing.label')}
            </Typography>
          </>
        }
      />
    ),
    [QuestionnaireStatus.Closed]: (
      <Tag
        style={statusBackground[QuestionnaireStatus.Closed]}
        label={
          <>
            <Typography className='text-inherit' type={'14-M'}>
              {questionnaireTranslations('questionnaireStatus.closed.label')}
            </Typography>
          </>
        }
      />
    ),
  };

  return { questionnaireStatuses, statusBackground };
};
