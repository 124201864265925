import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { Button } from '@/components/Button/Button';
import { ArrowRightIcon } from '@/components/Icons/ArrowRightIcon';
import { BarChartIcon } from '@/components/Icons/BarChartIcon';
import { CheckedCircleIcon } from '@/components/Icons/CheckedCircleIcon';
import { ListViewIcon } from '@/components/Icons/ListViewIcon';
import { TrashIcon } from '@/components/Icons/TrashIcon';
import { ITableColumn, Table } from '@/components/Table/Table';
import { PerformanceProfileViews } from '@/core/enums/functions/performanceProfile/performanceProfileViewEnum';
import { QuestionnaireStatus } from '@/core/enums/functions/performanceProfile/questionnaires/questionnaireStatusEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { performanceProfileSelectors } from '@/core/redux/slices/functions/performanceProfile/selectors';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';
import { colorTypes } from '@/styles/types';

import { CompetenciesCharts } from '../CompetenciesCharts/CompetenciesCharts';
import { useQuestionnaireTags } from '../Questionnaire/hooks/useQuestionnaireTags';
import { Questionnaire } from '../Questionnaire/Questionaire';

import { QuestionnaireHistoryControl } from './QuestionnaireHistoryControl/QuestionnaireHistoryControl';

interface IQuestionnaireHistory {
  onViewChange: (view: PerformanceProfileViews) => void;
}

interface ITableView {
  id: number;
  view: PerformanceProfileViews;
}

export const QuestionnaireHistory: React.FC<IQuestionnaireHistory> = ({ onViewChange }) => {
  const { selectedPersonID } = useSelectedPerson();
  const { questionnaireStatuses } = useQuestionnaireTags();

  const { t: questionnaireHistoryTranslations } = useTranslation('performanceProfile', {
    keyPrefix: 'questionnaireHistory',
  });

  const [selectedView, setSelectedView] = useState<ITableView | undefined>();
  const [selectedCompetenceAnalysisId, setSelectedCompetenceAnalysisId] = useState<number[]>([]);

  const history = useAppSelector(performanceProfileSelectors.history);
  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const detailIDs = useAppSelector(performanceProfileSelectors.detailsIDs);

  const dispatch = useAppDispatch();

  const handleQuestionnaireSelect = (selected: number[]) => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      performanceProfileActions.setViewDetailsIDs({
        personID: selectedPersonID,
        competenceAnalysisIDs: selected,
      })
    );
  };

  const handleComplete = (id: number) => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      performanceProfileActions.completeQuestionnaire({
        competenceAnalysisID: id,
        personID: selectedPersonID,
      })
    );
  };

  const handleApprove = (id: number) => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      performanceProfileActions.approveQuestionnaire({
        competenceAnalysisID: id,
        personID: selectedPersonID,
      })
    );
  };

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(performanceProfileActions.fetchHistory({ personID: selectedPersonID }));
  }, [selectedPersonID]);

  useEffect(() => {
    if (!detailIDs || !selectedPerson) {
      return;
    }

    if (selectedPerson.id in detailIDs) {
      const competenceAnalysisID = detailIDs[selectedPerson.id];
      setSelectedCompetenceAnalysisId(competenceAnalysisID);
      return;
    }
  }, [detailIDs, selectedPersonID]);

  const renderView = (id: number, view: PerformanceProfileViews): ReactNode => {
    switch (view) {
      case PerformanceProfileViews.Questionnaire:
        return <Questionnaire competenceAnalysisID={id} />;
      case PerformanceProfileViews.Charts:
        return <CompetenciesCharts competenceAnalysisIDs={[id]} />;
      default:
        return null;
    }
  };

  const columns: ITableColumn[] = [
    {
      header: questionnaireHistoryTranslations('table.header.catalogType.label'),
      accessor: 'targetCatalogType',
    },
    {
      header: questionnaireHistoryTranslations('table.header.status.label'),
      accessor: 'statusText',
      template: (status: QuestionnaireStatus) => {
        return questionnaireStatuses[status];
      },
    },
    {
      header: questionnaireHistoryTranslations('table.header.createdBy.label'),
      accessor: 'createdBy',
    },
    {
      header: questionnaireHistoryTranslations('table.header.createdDate.label'),
      accessor: 'createdTimestamp',
      template: (date: string) => toClientDateInput(date),
    },
    {
      header: '',
      accessor: '',
      className: 'py-2',
      isSortable: false,
      template: (_, row) => {
        const isQuestionnaireActive =
          selectedView &&
          selectedView.id === row.id &&
          selectedView.view === PerformanceProfileViews.Questionnaire;
        const isChartsActive =
          selectedView &&
          selectedView.id === row.id &&
          selectedView.view === PerformanceProfileViews.Charts;

        const isComplete = row.statusText === 'zur Freigabe' || row.statusText === QuestionnaireStatus.Complete;
        const isReadyForCLosing = row.statusText === QuestionnaireStatus.ReadyForClosing;

        return (
          <div className={'flex flex-row items-center'}>
            <>
              <Button
                data-tooltip-id='nextStatus'
                data-tooltip-content={questionnaireHistoryTranslations(
                  'table.actions.nextStatus.label'
                )}
                buttonVariant={'Icon'}
                buttonStyle={'Icon'}
                className={twMerge(`text-${colorTypes.Grey} p-2 hover:bg-stroke rounded-md`, !isComplete && 'pointer-events-none invisible')}
                onClick={(e) => {
                  e.stopPropagation();
                  handleApprove(row.id);
                }}
              >
                <ArrowRightIcon className={'w-[24px] h-[24px]'} />
              </Button>
              <Tooltip id={'nextStatus'} style={{ zIndex: 100000 }} />
            </>
            <>
              <Button
                data-tooltip-id='completeProfile'
                data-tooltip-content={questionnaireHistoryTranslations(
                  'table.actions.completeProfile.label'
                )}
                buttonVariant={'Icon'}
                buttonStyle={'Icon'}
                className={twMerge(
                  `text-${colorTypes.Grey}  p-2 hover:bg-stroke  rounded-md`,
                  !isReadyForCLosing && 'pointer-events-none invisible'
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  handleComplete(row.id);
                }}
              >
                <CheckedCircleIcon className={'w-[24px] h-[24px]'} />
              </Button>
              <Tooltip id={'completeProfile'} style={{ zIndex: 100000 }} />
            </>

            <>
              <Button
                data-tooltip-id='displayAssessments'
                data-tooltip-content={questionnaireHistoryTranslations(
                  'table.actions.displayAssessments.label'
                )}
                buttonVariant={'Icon'}
                buttonStyle={'Icon'}
                className={twMerge(
                  `text-${colorTypes.Grey}  p-2 hover:bg-stroke  rounded-md`,
                  isQuestionnaireActive && `bg-stroke text-blue`
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  !isQuestionnaireActive
                    ? setSelectedView({
                        id: row.id,
                        view: PerformanceProfileViews.Questionnaire,
                      })
                    : setSelectedView(undefined);
                }}
              >
                <ListViewIcon className={'w-[24px] h-[24px]'} />
              </Button>
              <Tooltip id={'displayAssessments'} style={{ zIndex: 100000 }} />
            </>

            <>
              <Button
                data-tooltip-id='graphicalRepresentation'
                data-tooltip-content={questionnaireHistoryTranslations(
                  'table.actions.graphicalRepresentation.label'
                )}
                buttonVariant={'Icon'}
                buttonStyle={'Icon'}
                className={twMerge(
                  `text-${colorTypes.Grey}  p-2 hover:bg-stroke  rounded-md`,
                  isChartsActive && 'bg-stroke text-blue'
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  !isChartsActive
                    ? setSelectedView({
                        id: row.id,
                        view: PerformanceProfileViews.Charts,
                      })
                    : setSelectedView(undefined);
                }}
              >
                <BarChartIcon className={'w-[24px] h-[24px]'} />
              </Button>
              <Tooltip id={'graphicalRepresentation'} style={{ zIndex: 100000 }} />
            </>

            <>
              <Button
                buttonVariant={'Icon'}
                buttonStyle={'Icon'}
                className={`text-${colorTypes.Grey}  p-2 hover:bg-stroke hover:text-error rounded-md`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                data-tooltip-id='deleteProfile'
                data-tooltip-content={questionnaireHistoryTranslations(
                  'table.actions.deleteProfile.label'
                )}
              >
                <TrashIcon className={'w-[24px] h-[24px]'} />
              </Button>
              <Tooltip id={'deleteProfile'} style={{ zIndex: 100000 }} />
            </>
          </div>
        );
      },
    },
  ];

  return (
    <div className='flex flex-col justify-between h-full'>
      <Table
        data={history}
        columns={columns}
        onMultiSelectChange={handleQuestionnaireSelect}
        initialSelectedState={selectedCompetenceAnalysisId}
        renderNestedContent={(row, rowRefs) => {
          if (selectedView && selectedView.id === row.id) {
            const selectedRowRef = rowRefs.current.get(row.id);
            if (selectedRowRef) {
              selectedRowRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }

            return renderView(row.id, selectedView.view);
          }

          return null;
        }}
      />
    </div>
  );
};
