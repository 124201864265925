import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { FundingCycleSection } from '@/components/functions/FundingCycle/FundingCycleSection/FundingCycleSection';
import { FundingCycleSectionHeader } from '@/components/functions/FundingCycle/FundingCycleSectionHeader/FundingCycleSectionHeader';
import { PerformanceProfileViews } from '@/core/enums/functions/performanceProfile/performanceProfileViewEnum';
import { QuestionnaireStatus } from '@/core/enums/functions/performanceProfile/questionnaires/questionnaireStatusEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { performanceProfileSelectors } from '@/core/redux/slices/functions/performanceProfile/selectors';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';
import { LoadingStatus } from '@/types/loadingStatus';

import { PerformanceProfile } from '../../PerformanceProfile/PerformanceProfile';

export const PerformanceProfileView: React.FC = () => {
  const { t: performanceProfileTranslations } = useTranslation('performanceProfile');
  const detailsIDs = useAppSelector(performanceProfileSelectors.detailsIDs);
  const viewMode = useAppSelector(performanceProfileSelectors.viewMode);
  const history = useAppSelector(performanceProfileSelectors.history);
  const historyLock = useAppSelector(performanceProfileSelectors.historyLock);
  
  const isReadyToStartNew = historyLock === LoadingStatus.LOADED && history.length && history[0].statusText === QuestionnaireStatus.Closed;
  const isEmptyHistory = historyLock === LoadingStatus.LOADED && history.length === 0;

  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState<number[] | null>(null);

  const dispatch = useAppDispatch();
  const { selectedPersonID } = useSelectedPerson();

  const handleStartNewQuestionnaire = () => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      performanceProfileActions.startNewQuestionnaire({
        personID: selectedPersonID,
        targetCatalogID: null,
      })
    );
  };

  useEffect(() => {
    if (!selectedPersonID || !detailsIDs) {
      return;
    }

    if (selectedPersonID in detailsIDs) {
      setSelectedQuestionnaires(detailsIDs[selectedPersonID]);
    }
  }, [selectedPersonID, detailsIDs]);

  const onViewChange = (view: PerformanceProfileViews) => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      performanceProfileActions.setViewDetailsViewMode({
        personID: selectedPersonID,
        viewMode: view,
      })
    );
  };

  const PerformanceProfileControls = (
    <div className='flex gap-4'>
      {isReadyToStartNew || isEmptyHistory && (
        <Button
          onClick={handleStartNewQuestionnaire}
          className='text-sm h-9 px-5 py-2.5 leading-[140%]'
        >
          {performanceProfileTranslations(
            'questionnaireHistory.buttons.newPerformanceProfile.label'
          )}
        </Button>
      )}

      {selectedQuestionnaires && selectedQuestionnaires.length > 1 && (
        <Button
          onClick={() => onViewChange(PerformanceProfileViews.Charts)}
          className='text-sm h-9 px-5 py-2.5 leading-[140%]'
        >
          {performanceProfileTranslations(
            'questionnaireHistory.buttons.comparePerformanceProfiles.label'
          )}
        </Button>
      )}
    </div>
  );

  const currentViewMode = useMemo(
    () =>
      !selectedPersonID || !viewMode
        ? PerformanceProfileViews.QuestionnaireHistory
        : viewMode[selectedPersonID],
    [viewMode]
  );

  return (
    <div className='h-full flex flex-col gap-4 py-5 px-8'>
      <FundingCycleSection
        header={
          <FundingCycleSectionHeader
            controls={
              currentViewMode !== PerformanceProfileViews.Charts && PerformanceProfileControls
            }
            title={performanceProfileTranslations('title.label')}
          />
        }
        className={'flex-grow'}
      >
        <PerformanceProfile contentClassName='h-full' />
      </FundingCycleSection>
    </div>
  );
};
